<template>
  <Portlet
    :title="$t('softwareComponent.softwareComponentVersions')"
    icon="file"
    class="softwareComponentVersions"
  >
    <template slot="buttons">
      <font-awesome-icon
        :class="['alt-pointer color-primary mr-3', { 'fa-spin': reloadLoad }]"
        icon="sync-alt"
        style="vertical-align: middle; float: right"
        @click="reload()"
      />
      <button
        class="btn btn-sm btn-primary float-right mt-2 mb-2"
        @click="showSidebarAdd"
      >
        <font-awesome-icon
          class="mr-1"
          icon="plus"
        />
        <span>
          {{ $t("add") }}
        </span>
      </button>
    </template>
    <SoftwareComponentVersionsSingleList
      ref="softwareComponentVersionsSingleList"
      :installation-id="installationId"
      @startLoading="startLoading"
    />
  </Portlet>
</template>

<script>
export default {
  name: "SoftwareComponentVersionsView",
  components: {
    SoftwareComponentVersionsSingleList: () =>
      import(
        "@/components/SoftwareComponent/SoftwareComponentVersionsSingleList.vue"
      ),
  },
  props: {
    installationId: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: this.$t("softwareComponent.softwareComponent"),
    };
  },
  data () {
    return {
      reloadLoad: false
    }
  },
  methods: {
    showSidebarAdd () {
      this.$refs.softwareComponentVersionsSingleList.showAddSoftwareComponentVersionSingle();
    },
    reload () {
      this.$refs.softwareComponentVersionsSingleList.getSoftwareComponentVersionsSingles();
    },
    startLoading (val) {
      this.reloadLoad = val;
    }
  }
};
</script>
